import "../styles.css";

import { useEffect } from "react";
import * as Sentry from "@sentry/node";
import Script from "next/script";

Sentry.init({
  dsn: process?.env?.SENTRY_DSN,
  release: process?.env?.SENTRY_RELEASE
    ? `eventive-promo-next@${process?.env?.SENTRY_RELEASE}`
    : undefined,
});

import Head from "next/head";
import Router from "next/router";
import NProgress from "nprogress";

import { IntercomProvider } from "react-use-intercom";
import smoothscroll from "smoothscroll-polyfill";
if (typeof window !== "undefined") {
  smoothscroll.polyfill();
}
const GTM_ID = "AW-966139226";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

Router.events.on("routeChangeComplete", (url) => {
  setTimeout(() => {
    if (window.gtag) {
      window.gtag("config", GTM_ID, {
        page_location: url,
        page_title: document.title,
      });
    }
  }, 0);
  setTimeout(() => {
    if (window.gtag) {
      window.gtag("config", GTM_ID, {
        page_location: url,
        page_title: document.title,
      });
    }
  }, 0);
});

function MyAppComponent({ Component, ...rest }) {


  // const {boot} = useIntercom();
  //
  // if (typeof window !== "undefined") {
  //   boot({
  //     name: 'Test User',
  //     email: 'emal@email.com'
  //   })
  // }


  useEffect(() => {
      CookieConsent.run({
        categories: {
          necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true  // this category cannot be disabled
          },
          analytics: {},
          advertising: {},
        },
        language: {
          default: 'en',
          translations: {
            en: {
              consentModal: {
                title: 'Manage cookie preferences',
                description: 'We use cookies to improve your experience. Some cookies are necessary for our website and services to function properly. Other cookies are optional and help personalize your experience, including advertising and analytics. You can consent to all cookies, decline all optional cookies, or manage optional cookies. Without a selection, our default cookie settings will apply.',
                acceptAllBtn: 'Accept all',
                acceptNecessaryBtn: 'Reject all',
                showPreferencesBtn: 'Manage Individual preferences'
              },
              preferencesModal: {
                title: 'Manage cookie preferences',
                acceptAllBtn: 'Accept all',
                acceptNecessaryBtn: 'Reject all',
                savePreferencesBtn: 'Accept current selection',
                closeIconLabel: 'Close modal',
                sections: [
                  {
                      title: '',
                      description: 'We use cookies to improve your experience. Some cookies are necessary for our website and services to function properly. Other cookies are optional and help personalize your experience, including advertising and analytics. You can consent to all cookies, decline all optional cookies, or manage optional cookies. Without a selection, our default cookie settings will apply.'
                  },
                  {
                      title: 'Strictly Necessary cookies',
                      description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',

                      //this field will generate a toggle linked to the 'necessary' category
                      linkedCategory: 'necessary'
                  },
                  {
                      title: 'Advertising',
                      description: 'These cookies are used to make advertising messages more relevant to users. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, and in some cases selecting advertisements that are based on user interests.',
                      linkedCategory: 'advertising'
                  },
                  {
                      title: 'Performance and Analytics',
                      description: 'These cookies collect information about how you use our website and to help us customize the website for you.',
                      linkedCategory: 'analytics'
                  },
                  {
                      title: 'More information',
                      description: 'For any queries in relation to my policy on cookies and your choices, please see our <a href="https://eventive.org/privacy" target="_blank">privacy policy</a>'
                  }
                ]
              }
            }
          }
        }
      });
  }, []);


  return (
    <>
      <Head>
        <meta name="apple-mobile-web-app-title" content="Eventive" />
        <meta property="ix:autoInit" content="false" />
        <meta
          name="viewport"
          content="width=device-width,minimum-scale=1,initial-scale=1"
        />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v3.0.0/dist/cookieconsent.css"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://eventive.imgix.net/app_icon.png?fm=png&w=180"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://eventive.imgix.net/app_icon.png?fm=png&w=32"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="https://eventive.imgix.net/app_icon.png?fm=png&w=16"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '399938309154797');
              fbq('track', 'PageView');
            `,
          }}
        />
      </Head>
      <Script
        src={`https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v3.0.0/dist/cookieconsent.umd.js`}
        strategy="beforeInteractive"
      />
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GTM_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GTM_ID}');
        `}
      </Script>
      <Component {...rest} />
    </>
  );
}

export default function MyApp({ Component, pageProps }) {
  return (
    <IntercomProvider appId={process?.env?.INTERCOM_APP_ID} autoBoot>
      <MyAppComponent Component={Component} {...pageProps} />;
    </IntercomProvider>
  );
}
